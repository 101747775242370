import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout"

export default function Home() {
  return (
    <StaticQuery
      query={graphql`
        query ImpressumQuery {
          site {
            siteMetadata {
              title
              description
              keywords
              firma
              address {
                street
                number
                zip
                city
              }
              email
              ceo
              amtsgericht
              hrb
              steuernummer
            }
          }
        }
      `}
      render={data => {
        let {
          title,
          description,
          keywords,
          firma,
          address,
          email,
          ceo,
          amtsgericht,
          hrb,
          steuernummer,
        } = data.site.siteMetadata
        return (
          <Layout>
            <Helmet
              title={title}
              meta={[
                { name: "description", content: description },
                { name: "keywords", content: keywords },
                { name: "robots", content: "noindex, nofollow" },
              ]}
              defer={false}
            >
              <html lang="de" />
            </Helmet>

            <Link to="/">zur Startseite</Link>

            <div className="container">
              <h1>Impressum</h1>

              <p>Angaben gemäß § 5 TMG</p>
              <br />

              <p>
                {firma}
                <br />
                {address.street} {address.number}
                <br />
                {address.zip} {address.city}
                <br />
                <br />
                E-Mail: {email}
                <br />
                <br />
                Geschäftsführer: {ceo}
                <br />
                {amtsgericht}, HRB-Nr.: {hrb}
                <br />
                Steuernummer: {steuernummer}
              </p>

              <br />
              <h2>Online-Streitbeilegung</h2>

              <p>
                Die Europäische Kommission stellt unter
                https://ec.europa.eu/consumers/odr/ eine Plattform zur
                Online-Streitbeilegung bereit, die Verbraucher für die Beilegung
                einer Streitigkeit nutzen können und auf der weitere
                Informationen zum Thema Streitschlichtung zu finden sind.
              </p>

              <br />
              <h2>Außergerichtliche Streitbeilegung</h2>

              <p>
                Wir sind weder verpflichtet noch dazu bereit, im Falle einer
                Streitigkeit mit einem Verbraucher an einem
                Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
              </p>
            </div>
          </Layout>
        )
      }}
    />
  )
}
